<template>
  <div id="realtimeVehicle" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between">
        <div class="N-Page T-size-36">Vehicle Monitor</div>
        <div class="box-S1 box-S1-80">
          <v-menu
            v-model="menuselectdate"
            :close-on-content-click="false"
            :nudge-right="0"
            :nudge-top="10"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="input-date"
                append-icon="mdi-calendar-range"
                v-model="dateSelect"
                label="Date"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateSelect"
              @input="menuselectdate = false"
              @change="RenderTransport()"
            ></v-date-picker>
          </v-menu>
          <!-- <v-select
            class="hideMessage"
            v-model="selectolicenseplate"
            item-text="o_licenseplate"
            item-value="o_id"
            :items="dataselect"
            label="License Plate"
            @change="renderline(selectolicenseplate)"
          ></v-select> -->
        </div>
      </div>
    </div>

    <div id="ownerContent" class="wrap-Main">
      <div class="box-S4 flex-between-center noPadding">
        <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">License Plate</div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <div class="T-size-16 SemiBold">
                    {{ licenseplate }}
                  </div>
                  <div class="T-size-16 SemiBold"><br /></div>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>

        <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Driver License</div>
            <span class="center">
              <v-row>
                <v-col md="12" v-if="driver != '-'">
                  <div class="T-size-16 SemiBold">
                    <v-icon>mdi mdi-account-circle</v-icon> {{ driver }}
                  </div>
                  <div class="T-size-16 SemiBold">
                    <!-- Expire date 25 July 2023 --><br />
                  </div>
                </v-col>
                <v-col md="12" v-else>
                  <div class="T-size-16 SemiBold">{{ driver }}</div>
                  <div class="T-size-16 SemiBold"><br /></div>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>

        <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Speed</div>
            <span class="center">
              <v-row>
                <v-col md="5" v-if="maxSpeed != null" class="T-size-16 SemiBold"
                  >Max Speed<br />{{ maxSpeed }}</v-col
                >
                <v-col md="5" v-else class="T-size-16 SemiBold"
                  >Max Speed<br />-</v-col
                >
                <v-col md="2" class="T-size-24">|</v-col>
                <v-col
                  md="5"
                  v-if="averageSpeed != null"
                  class="T-size-16 SemiBold"
                  >Average Speed<br />{{ averageSpeed }}</v-col
                >
                <v-col md="5" v-else class="T-size-16 SemiBold"
                  >Average Speed<br />-</v-col
                >
              </v-row>
            </span>
          </div>
        </div>

        <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Time Spent</div>
            <div class="T-size-20 SemiBold"></div>
            <span class="center">
              <v-row>
                <v-col
                  md="12"
                  v-if="timeSpent != null"
                  class="T-size-16 SemiBold"
                  ><p>{{ timeSpent }}</p></v-col
                >
                <v-col md="12" v-else class="T-size-16 SemiBold"
                  ><p>-</p></v-col
                >
              </v-row>
            </span>
            <span class="center"></span>
          </div>
        </div>

        <!-- <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Maintemamce within</div>
            <span class="center">
              <v-row>
                <v-col
                  md="5"
                  class="color-red T-size-16 SemiBold"
                  v-if="mtDistance != null"
                  >Distance<br />{{ mtDistance }} km</v-col
                >
                <v-col md="5" class="color-red T-size-16 SemiBold" v-else
                  >Distance<br />-</v-col
                >
                <v-col md="2" class="T-size-24">|</v-col>
                <v-col md="5" class="color-red T-size-16 SemiBold" v-if="mtTime"
                  >Time<br />{{ mtTime }} Houese</v-col
                >
                <v-col md="5" class="color-red T-size-16 SemiBold" v-else
                  >Time<br />-</v-col
                >
              </v-row>
            </span>
          </div>
        </div> -->

        <!-- <div class="box-S1">
          <div class="B-carddetail">
            <div class="panal-title">Driver Rating</div>
            <div class="T-size-20 SemiBold"></div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <div class="T-size-16 SemiBold"><br /></div>
                </v-col>
              </v-row>
            </span>
            <span class="center"></span>
          </div>
        </div> -->
      </div>
    </div>

    <div id="realtimeMap" class="wrap-Main">
      <div class="box-S3">
        <div style="padding-left: 47%; padding-top: 25%; position: absolute">
          <span id="displayLoader" style="display: none">
            <div id="loader" class="loader"></div>
          </span>
        </div>
        <l-map :zoom="zoom" :center="center" style="z-index: 0; height: 65vh">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="center" :icon="iconcenter" />
          <v-rotated-marker
            class="marker"
            v-for="(item, index) in markers"
            :key="'marker-' + index"
            :lat-lng="item.location"
            :icon="icon"
            :rotationAngle="item.rotate"
          >
            <l-popup>{{ item.name }}</l-popup>
          </v-rotated-marker>
          <l-polyline :lat-lngs="latlngs" color="#0BCC01"></l-polyline>
          <l-polyline :lat-lngs="latlngs2" color="#0000FF"></l-polyline>
        </l-map>
      </div>

      <div class="box-S1">
        <v-row>
          <v-col md="12">
            <div class="B-carddetail">
              <div class="panal-title">Pick up spots</div>
              <span v-if="pickUpSport.length > 0">
                <v-list-item class="T-size-16">
                  <v-list-item-content class="center"
                    ><v-icon
                      >mdi mdi-account-circle</v-icon
                    ></v-list-item-content
                  >
                  <v-list-item-content class="center"
                    ><v-icon>mdi mdi-map-marker</v-icon
                    >Pickup</v-list-item-content
                  >
                  <v-list-item-content class="center"
                    ><v-icon>mdi mdi-map-marker</v-icon
                    >Drop</v-list-item-content
                  >
                </v-list-item>

                <v-list
                  class="T-size-14"
                  v-for="item in pickUpSport"
                  :key="item.ts_id"
                >
                  <!-- <div @click="renderline(item.ts_id)"> -->
                  <div @click="renderLineInterval(item.ts_id)">
                    <span
                      class="color-red item-click"
                      v-if="item.tsl_picup_name == ''"
                    >
                      <div class="line"></div>
                    </span>
                    <v-list-item>
                      <v-list-item-content
                        v-if="item.tsl_picup_name != ''"
                        class="center T-size-16"
                        >{{ item.u_firstname }}
                        <div><br /></div
                      ></v-list-item-content>
                      <v-list-item-content
                        v-else
                        class="T-size-18 SemiBold color-red"
                        >{{ item.u_firstname }}</v-list-item-content
                      >
                      <v-list-item-content class="center T-size-16"
                        >{{ item.tsl_picup_name }}
                        <p>
                          <span v-if="item.tsj_picup_time != ''">{{
                            item.tsj_picup_time | HHmm
                          }}</span
                          ><span v-if="item.tsj_picup_time_actual != null">
                            / {{ item.tsj_picup_time_actual | HHmm }}</span
                          >
                        </p></v-list-item-content
                      >
                      <v-list-item-content class="center T-size-16"
                        >{{ item.tsl_drop_name }}
                        <p>
                          <span v-if="item.tsj_picup_time != ''">{{
                            item.tsj_drop_time | HHmm
                          }}</span
                          ><span v-if="item.tsj_picup_time_actual != null">
                            / {{ item.tsj_drop_time_actual | HHmm }}</span
                          >
                        </p></v-list-item-content
                      >
                    </v-list-item>
                  </div>
                </v-list>
              </span>
              <span v-else>
                <div class="center">No Transport</div>
              </span>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="box-S2 center">
        <v-row>
          <v-col md="5" class="center T-size-16 SemiBold"
            >Speed
            <div class="center T-size-18 SemiBold">
              {{ speed }}
            </div>
          </v-col>
          <v-col md="1" class="T-size-24 center">|</v-col>
          <v-col md="5" class="center T-size-16 SemiBold"
            >Location
            <div
              class="center T-size-18 SemiBold"
              v-if="locationLat != '-' && locationLong != '-'"
            >
              {{ locationLat }}, {{ locationLong }}
            </div>
            <div class="center T-size-18 SemiBold" v-else>-</div>
          </v-col>
          <!-- <v-col md="1" class="T-size-24">|</v-col>
          <v-col md="3" class="center T-size-16 SemiBold"
            >Total Distance
            <div class="color-red center T-size-16 SemiBold">120 Km</div>
          </v-col> -->
        </v-row>
      </div>
      <div class="box-S1">
        <v-row></v-row>
      </div>

      <!-- <div class="box-S4">
        <v-col md="4">
          <div class="B-carddetail">
            <div class="panal-title">Event log</div>
            <div class="T-size-20 SemiBold"></div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <v-list-item
                    class="T-size-14"
                    v-for="item in itemEventLog"
                    :key="item.Id"
                  >
                    {{ item.Time
                    }}<v-icon color="indigo">mdi mdi-alert-circle</v-icon
                    >{{ item.LogDetail }}
                  </v-list-item>
                </v-col>
              </v-row>
            </span>
          </div>
        </v-col>

        <v-col md="5">
          <div class="B-carddetail">
            <div class="panal-title">Comment</div>
            <div class="T-size-20 SemiBold"></div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <v-list-item
                    class="T-size-14"
                    v-for="item in itemEventLog"
                    :key="item.Id"
                  >
                    {{ item.Time
                    }}<v-icon color="indigo">mdi mdi-alert-circle</v-icon
                    >{{ item.LogDetail }}
                  </v-list-item>
                </v-col>
              </v-row>
            </span>
          </div>
        </v-col>

        <v-col md="3" style="padding-left: 2.5%">
          <div class="B-carddetail">
            <div class="panal-title">Driver Rating</div>
            <div class="T-size-20 SemiBold"></div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                </v-col>
              </v-row>
            </span>
          </div>
        </v-col>
      </div> -->
    </div>

    <!-- <div id="ownerContent" class="wrap-Main">
      <div class="box-S4 flex-between-center noPadding">
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="panal-title">Event log</div>
            <div class="T-size-20 SemiBold"></div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <v-list-item
                    class="T-size-14"
                    v-for="item in itemEventLog"
                    :key="item.Id"
                  >
                    <div class="color-red T-size-16 SemiBold">
                      {{ item.Time }}
                      <v-icon color="indigo">mdi mdi-alert-circle</v-icon>
                      {{ item.LogDetail }}
                    </div>
                  </v-list-item>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="panal-title">Comment</div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <v-list-item
                    class="T-size-14"
                    v-for="item in itemEventLog"
                    :key="item.Id"
                  >
                    <div class="color-red T-size-16 SemiBold">
                      {{ item.Time }}
                      <v-icon color="indigo">mdi mdi-alert-circle</v-icon>
                      {{ item.LogDetail }}
                    </div>
                  </v-list-item>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>
        <div class="box-S1-3">
          <div class="B-carddetail">
            <div class="panal-title">Driver Rating</div>

            <div class="T-size-20 SemiBold"></div>
            <span class="center">
              <v-row>
                <v-col md="12">
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                  <v-icon>mdi mdi-star</v-icon>
                </v-col>
              </v-row>
            </span>
          </div>
        </div>
      </div>
    </div> -->

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { latLng, icon } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIconDefault,
  LPolyline,
  LIcon,
  LPopup,
} from "vue2-leaflet";
import {
  startOfDay,
  endOfDay,
  format,
  addHours,
  differenceInMinutes,
} from "date-fns";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import feathersClientVan from "../plugins/feathers-client";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import fillerdate from "../utils/filterdate";

export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LPopup,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },
  data() {
    return {
      myTimer: null,

      zoom: 20,
      center: L.latLng(7.1874908, 100.6022255),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markers: [],
      icon: icon({
        // iconUrl: "http://203.151.121.245:9062/images/car.png",
        iconUrl: "https://api.prodigyfly.com/uploads/car.svg",
        iconSize: [28, 60],
        iconAnchor: [14, 30],
      }),
      iconcenter: icon({
        // iconUrl: "https://image.flaticon.com/icons/png/512/1518/1518388.png",
        iconUrl: "https://api.prodigyfly.com/uploads/airport.svg",
        iconSize: [30, 50],
        iconAnchor: [16, 16],
      }),
      latlngs: [],
      latlngs2: [],
      dataselect: [],
      selectolicenseplate: "",
      tempdatalat: 0,
      tempdatalng: 0,

      pickUpSport: [],
      itemEventLog: [
        {
          Id: 1,
          Time: "7:46",
          LogDetail: "Speed over limit ar 120 km/h",
        },
      ],

      //Add
      userdata: null,
      dateSelect: new Date(),
      menuselectdate: false,
      dataTracking: [],
      mtDistance: null,
      mtTime: null,

      licenseplate: "-",
      driver: "-",
      fuelRemain: "-",
      fuelConsumption: "-",
      speed: "-",
      locationLat: "-",
      locationLong: "-",
      altitude: "-",
      totalDistance: "-",

      maxSpeed: null,
      averageSpeed: null,
      timeSpent: null,
    };
  },
  async mounted() {
    this.userdata = this.$CurrentUser.GetCurrentUser();
    //await this.renderSelect();
    this.dateSelect = format(this.dateSelect, "yyyy-MM-dd");
    //Only Test
    //this.dateSelect = format(new Date("2020-11-18"), "yyyy-MM-dd");
    this.RenderTransport();

    //ย้ายไปข้างล่าง
    // feathersClientVan.service("tracking").on("created", (data) => {
    //   //if (this.selectolicenseplate == data.t_licenseplate) {
    //   //Edit
    //   if (this.selectolicenseplate.o_licenseplate == data.t_licenseplate) {
    //     let rotate =
    //       Math.atan2(
    //         data.t_lat - this.tempdatalat,
    //         data.t_lng - this.tempdatalng
    //       ) *
    //       (270 / Math.PI) *
    //       -1;
    //     if (rotate < 0.0) rotate += 360.0;
    //     let radian = rotate.toFixed(5);
    //     this.markers = [];
    //     var c = {};
    //     c["location"] = eval("L.latLng(" + data.t_lat + "," + data.t_lng + ")");
    //     c["rotate"] = radian;
    //     c["name"] = data.t_licenseplate;
    //     this.markers.push(c);
    //     this.latlngs2.push(eval("[" + data.t_lat + "," + data.t_lng + "]"));
    //     this.tempdatalat = data.t_lat;
    //     this.tempdatalng = data.t_lng;
    //   }
    // });
    // this.renderline();
  },
  methods: {
    MinutesConvertTohhmm(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return rhours + ":" + rminutes;
    },
    Back() {
      this.$router.go(-1);
    },
    async renderSelect() {
      try {
        const q = {};
        var res = await feathersClientVan
          .service("onlinevan")
          .find({ query: q });
        this.markers = [];
        //Edit
        this.dataselect = res.data;
        this.selectolicenseplate = this.dataselect[1];
      } catch (err) {
        this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
      }
    },
    // async renderline(licenseplate) {
    //   if (licenseplate == null) {
    //     licenseplate = "นข4557 สงขลา";
    //   }
    //   try {
    //     var sDate = startOfDay(new Date());
    //     var eDate = endOfDay(new Date());
    //     var x = format(addHours(sDate, 7), "yyyy-MM-dd HH:mm:ss");
    //     var y = format(addHours(eDate, 7), "yyyy-MM-dd HH:mm:ss");
    //     const q = {
    //       t_datetime: {
    //         $gt: x,
    //         $lt: y,
    //       },
    //       t_licenseplate: licenseplate,
    //       $sort: {
    //         t_datetime: -1,
    //       },
    //     };
    //     var res = await feathersClientVan
    //       .service("tracking")
    //       .find({ query: q });
    //     this.latlngs = [];
    //     this.latlngs2 = [];
    //     res.data.forEach((element) => {
    //       this.latlngs.push(
    //         eval("[" + element.t_lat + "," + element.t_lng + "]")
    //       );
    //     });

    //     let rotate =
    //       Math.atan2(
    //         res.data[0].t_lat - this.tempdatalat,
    //         res.data[0].t_lng - this.tempdatalng
    //       ) *
    //       (270 / Math.PI) *
    //       -1;
    //     if (rotate < 0.0) rotate += 360.0;
    //     let radian = rotate.toFixed(5);
    //     this.markers = [];
    //     var c = {};
    //     c["location"] = eval(
    //       "L.latLng(" + res.data[0].t_lat + "," + res.data[0].t_lng + ")"
    //     );
    //     c["rotate"] = radian;
    //     c["name"] = res.data[0].t_licenseplate;
    //     this.markers.push(c);
    //   } catch (err) {
    //     //this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
    //     //alert(err);
    //   }
    // },

    async renderLineInterval(transportId) {
      this.renderline(transportId);
      if (this.myTimer != null) {
        this.latlngs = [];
        this.latlngs2 = [];
        clearInterval(this.myTimer);
        this.myTimer = null;
      }
      this.myTimer = setInterval(() => {
        this.renderline(transportId);
      }, 1000 * 30);
    },

    //Edit
    async renderline(transportId) {
      this.maxSpeed = null;

      document.getElementById("displayLoader").style.display = null;
      try {
        const q = {
          t_transportation_id: transportId,
          $sort: {
            t_id: -1,
          },
        };
        var res = await feathersClientVan
          .service("tracking")
          .find({ query: q });
        let trackingData = res.data;

        if (res.total > 0) {
          var last = res.total - 1;
          var tackingDetail = trackingData[last];

          this.licenseplate = tackingDetail.t_licenseplate;
          this.driver = tackingDetail.t_driver;
          this.fuelRemain = tackingDetail.t_fuel;
          this.fuelConsumption = tackingDetail.t_fuel_consumption;

          this.speed = tackingDetail.t_speed + " km/h";
          this.locationLat = tackingDetail.t_lat;
          this.locationLong = tackingDetail.t_lng;

          let countSpeed = 0;
          let sumSpeed = 0;

          this.latlngs = [];
          this.latlngs2 = [];
          trackingData.forEach((element) => {
            if (element.t_speed > this.maxSpeed) {
              this.maxSpeed = element.t_speed;
            }
            if (element.t_speed > 0) {
              countSpeed += 1;
              sumSpeed = sumSpeed + element.t_speed;
            }
            this.latlngs.push(
              eval("[" + element.t_lat + "," + element.t_lng + "]")
            );
          });

          this.averageSpeed = (sumSpeed / countSpeed).toFixed(2);

          this.timeSpent = this.MinutesConvertTohhmm(
            differenceInMinutes(
              new Date(trackingData[0].t_datetime),
              new Date(trackingData[last].t_datetime)
            )
          );

          let rotate =
            Math.atan2(
              res.data[0].t_lat - this.tempdatalat,
              res.data[0].t_lng - this.tempdatalng
            ) *
            (270 / Math.PI) *
            -1;
          if (rotate < 0.0) rotate += 360.0;
          let radian = rotate.toFixed(5);
          this.markers = [];
          var c = {};
          c["location"] = eval(
            "L.latLng(" + res.data[0].t_lat + "," + res.data[0].t_lng + ")"
          );
          c["rotate"] = radian;
          c["name"] = res.data[0].t_licenseplate;

          this.center = eval(
            "L.latLng(" + res.data[0].t_lat + "," + res.data[0].t_lng + ")"
          );

          this.markers.push(c);
        } else {
          this.latlngs = [];
          this.latlngs2 = [];
        }

        document.getElementById("displayLoader").style.display = "none";
      } catch (err) {
        //this.$toast.error("ไม่สามารถติดต่อ server ได้" + err);
        //alert(err);
        document.getElementById("displayLoader").style.display = "none";
      }
    },

    async RenderTransport() {
      //Step clear data
      this.pickUpSport = [];
      this.licenseplate = "-";
      this.driver = "-";
      this.fuelRemain = "-";
      this.fuelConsumption = "-";
      this.speed = "-";
      this.locationLat = "-";
      this.locationLong = "-";
      this.altitude = "-";
      this.totalDistance = "-";

      //step 1 quary transport by o_id
      var quaryTransport = {
        $sort: {
          pt_id: 1,
        },
      };
      if (this.userdata.r_id == 2) {
        quaryTransport.o_id = this.userdata.o_id;
      }
      quaryTransport.ts_date = {
        $gt: format(
          startOfDay(new Date(this.dateSelect)),
          "yyyy-MM-dd HH:mm:ss"
        ),
        $lt: format(endOfDay(new Date(this.dateSelect)), "yyyy-MM-dd HH:mm:ss"),
      };
      var res = await feathersClientUOA
        .service("view-transport-detail")
        .find({ query: quaryTransport });
      var count = res.total;
      var pickUpSport = res.data;

      if (count > 0) {
        var out_ts_id = 0;
        var i = 1;
        pickUpSport.forEach((element) => {
          if (out_ts_id != element.ts_id) {
            out_ts_id = element.ts_id;
            add = {
              ts_id: element.ts_id,
              job: "",
              u_firstname: "JOB " + i,

              tsl_picup_name: "",
              tsj_picup_time: "",
              tsj_picup_time_actual: null,

              tsl_drop_name: "",
              tsj_drop_time: "",
              tsj_drop_time_actual: null,
            };
            this.pickUpSport.push(add);

            i++;
          }

          var add = {};
          if (out_ts_id == element.ts_id) {
            add = {
              ts_id: element.ts_id,
              job: "",
              u_firstname: element.u_firstname + " " + element.u_lastname,

              tsl_picup_name: element.tsl_picup_name,
              // tsj_picup_time: element.tsj_picup_time,
              tsj_picup_time: element.tsj_picup_time,
              tsj_picup_time_actual: element.tsj_picup_time_actual,

              tsl_drop_name: element.tsl_drop_name,
              tsj_drop_time: element.tsj_drop_time,
              tsj_drop_time_actual: element.tsj_drop_time_actual,
            };
            this.pickUpSport.push(add);
          }
        });

        //step 2 quary tracking by date and tr
        var queryTacking = {};
        //set transport 1
        queryTacking.t_transportation_id = this.pickUpSport[1].ts_id;
        //Start of day 00:00:00, 23:59:59
        queryTacking.t_datetime = {
          $gt: format(
            startOfDay(new Date(this.dateSelect)),
            "yyyy-MM-dd HH:mm:ss"
          ),
          $lt: format(
            endOfDay(new Date(this.dateSelect)),
            "yyyy-MM-dd HH:mm:ss"
          ),
        };
        // var dataTacking = await feathersClientVan
        //   .service("tracking")
        //   .find({ query: queryTacking });
        // this.dataTracking = dataTacking.data[0];
        //console.log("dataTacking : " + JSON.stringify(this.dataTracking));
        // alert(JSON.stringify(this.dataTracking.t_licenseplate));

        //รองรับรถคันเดียวอยู่
        //ย้ายมาจาก mounted
        feathersClientVan.service("tracking").on("created", (data) => {
          if (
            data.t_transportation_id == this.dataTracking.t_transportation_id
          ) {
            let rotate =
              Math.atan2(
                data.t_lat - this.tempdatalat,
                data.t_lng - this.tempdatalng
              ) *
              (270 / Math.PI) *
              -1;
            if (rotate < 0.0) rotate += 360.0;
            let radian = rotate.toFixed(5);
            this.markers = [];
            var c = {};
            c["location"] = eval(
              "L.latLng(" + data.t_lat + "," + data.t_lng + ")"
            );
            c["rotate"] = radian;
            c["name"] = data.t_licenseplate;
            this.markers.push(c);
            this.latlngs2.push(eval("[" + data.t_lat + "," + data.t_lng + "]"));
            this.tempdatalat = data.t_lat;
            this.tempdatalng = data.t_lng;
          }
        });
      }
    },

    async RenderTransportDetail(transportId) {
      // try {
      //   const q = {
      //     t_transportation_id: transportId,
      //     $sort: {
      //       t_datetime: 1,
      //     },
      //   };

      //   var res = await feathersClientVan
      //     .service("tracking")
      //     .find({ query: q });

      //   console.log("tracking : " + JSON.stringify(res));
      // } catch (err) {
      //   alert("ไม่สามารถติดต่อ server ได้" + err);
      // }
      await this.renderline(transportId);
    },
  },
};
</script>
<style>
.leaflet-marker-icon {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}
</style>

<style scoped>
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #e40813;
  width: 65px;
  height: 65px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  z-index: 100;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>